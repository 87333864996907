import React, { useState } from 'react';
import { DeveloperContentItemDetail } from 'interfaces';

import { Col } from 'antd';

import "antd/dist/reset.css";
import { Link } from 'react-router-dom';
import { buildSheetUrl, getStatusClass } from 'utils/functions';
import moment from 'moment';
import { SortOrder } from 'antd/es/table/interface';
import { IssueModal } from '../IssueModal';
import { StyledButton, StyledTable } from '../../dashboards.styled';

interface DeveloperTableProps {
  tableData: DeveloperContentItemDetail[];
  handleActionButtonClick: (subject_name: string, item: DeveloperContentItemDetail) => void;
  handleContentLog: (row_id: number) => void;
}

export const DeveloperTable: React.FC<DeveloperTableProps> = ({
  tableData,
  handleActionButtonClick,
  handleContentLog
}) => {

  const [hoveredRowIndex, setHoveredRowIndex] = useState<number | null>(null);
  const [viewLog, setViewLog] = useState<boolean | null>(null);

  const columns = [
    {
      title: 'Level',
      dataIndex: 'level',
      key: 'level',
      sorter: (a: any, b: any) => {
        const getNumericLevel = (level: string) => parseInt(level.replace(/\D/g, ''), 10);
        return getNumericLevel(a.level) - getNumericLevel(b.level);
      },
      onCell: (record: any, rowIndex: any) => ({
        style: {
          backgroundColor: getStatusClass(record),
        },
        onMouseEnter: () => handleMouseEnterConnected(rowIndex),
        onMouseLeave: () => handleMouseLeaveConnected(rowIndex),
      }),
      render: (text: string, record: any) => (
        record.capture_id && record.status !== "Complete" ?
          <Link to={buildSheetUrl(record.capture_id)} style={{ color: '#000' }} target='_blank' rel='noopener noreferrer'>
            <div className={"section-cell connected"}>
              <span style={{ paddingLeft: '1rem' }}>{text}</span>
            </div>
          </Link>
          :
          <div className={"section-cell connected"}>
            <span style={{ paddingLeft: '1rem' }}>{text}</span>
          </div>
      ),
    },
    {
      title: 'Content',
      dataIndex: 'content',
      key: 'content',
      sorter: (a: any, b: any) => a.content.localeCompare(b.content),
      onCell: (record: any, rowIndex: any) => ({
        style: {
          backgroundColor: getStatusClass(record),
        },
        onMouseEnter: () => handleMouseEnterConnected(rowIndex),
        onMouseLeave: () => handleMouseLeaveConnected(rowIndex),
      }),
      render: (text: string, record: any) => (
        record.capture_id && record.status !== "Complete" ?
          <Link to={buildSheetUrl(record.capture_id)} style={{ color: '#000' }} target='_blank' rel='noopener noreferrer'>
            <div className={"section-cell middle-cell connected"}>
              <span style={{ paddingLeft: '1rem' }}>{text}</span>
            </div>
          </Link>
          :
          <div className={"section-cell middle-cell connected"}>
            <span style={{ paddingLeft: '1rem' }}>{text}</span>
          </div>
      ),
    },

    {
      title: 'Subject',
      dataIndex: 'subject',
      key: 'subject',
      sorter: (a: any, b: any) => a.subject.localeCompare(b.subject),
      onCell: (record: any, rowIndex: any) => ({
        style: {
          backgroundColor: getStatusClass(record),
        },
        onMouseEnter: () => handleMouseEnterConnected(rowIndex),
        onMouseLeave: () => handleMouseLeaveConnected(rowIndex),
      }),
      render: (text: string, record: any) => (
        record.capture_id && record.status !== "Complete" ?
          <Link to={buildSheetUrl(record.capture_id)} style={{ color: '#000' }} target='_blank' rel='noopener noreferrer'>
            <div className={"section-cell connected"}>
              <span style={{ paddingLeft: '1rem' }}>{text}</span>
            </div>
          </Link>
          :
          <div className={"section-cell connected"}>
            <span style={{ paddingLeft: '1rem' }}>{text}</span>
          </div>
      ),
    },

    {
      title: 'Due Date',
      dataIndex: 'assigned_due',
      key: 'assigned_due',
      defaultSortOrder: 'ascend' as SortOrder,
      sorter: (a: any, b: any) => {
        if (!(moment(a.assigned_due, ["D/M/YYYY", "D/MM/YYYY"], true).isValid()) || !(["Overdue", "Reassigned", "In Progress"].includes(a.status))) {
          return 1;
        }
        if (!(moment(b.assigned_due, ["D/M/YYYY", "D/MM/YYYY"], true).isValid()) || !(["Overdue", "Reassigned", "In Progress"].includes(b.status))) {
          return -1;
        }
        return moment(a.assigned_due, ["D/M/YYYY", "D/MM/YYYY"], true).isBefore(moment(b.assigned_due, ["D/M/YYYY", "D/MM/YYYY"], true)) ? -1 : 1;
      },
      onCell: (record: any) => ({
        style: {
          backgroundColor: getStatusClass(record),
        },
      }),
      render: (text: string, record: any) => (
        text && record.status !== "Complete" ? moment(text, "DD/MM/YYYY").format("D MMM YYYY") : ""
      )
    },

    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      onCell: (record: any) => ({
        style: {
          backgroundColor: getStatusClass(record),
        },
      }),
      render: (_: string, record: any, rowIndex: any) => (
        <div style={{ position: 'relative'}}>
          <StyledButton
            onClick={() => handleActionButtonClick(record.subject, record)}
            type="primary"
            className={record.status === "Issue Reported" ? "disabled" : ""}
            disabled={record.status === "Issue Reported"}
          >
            Action
          </StyledButton>
          <div>
            {hoveredRowIndex === rowIndex && (
              <div 
                onClick={(e) => {
                  e.stopPropagation();
                  handleContentLog(record.id);
                }}
                onMouseEnter={ () => setViewLog(true)}
                onMouseLeave={ () => setViewLog(false)}
                style={{
                  position: "absolute",
                  top: "50%",
                  left: viewLog ? "130px" : "75px",
                  transform: "translateY(-50%)",
                  backgroundColor: "#f0f0f0",
                  padding: "16px 14px 16px 40px",
                  borderRadius: "0px 40px 40px 0px",
                  boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
                  whiteSpace: "nowrap",
                  cursor: "pointer",
                  transition: "left 0.5s ease",
                  zIndex: -1,
                }}
              >
                View Log
              </div>
            )}
          </div>
        </div>
      ),
    },
  ];

  const [open, setOpen] = useState(false);
  const [issueText, setIssueText] = useState(<></>);

  const onOkClick = () => {
    setOpen(false);
    setIssueText(<></>);
  }

  const handleMouseEnterConnected = (rowIndex: number) => {
    const connected = document.querySelectorAll('.ant-table-row')[rowIndex].querySelectorAll('.connected');
    connected.forEach((element) => {
      element.classList.add('hover-effect');
    });
  };

  const handleMouseLeaveConnected = (rowIndex: number) => {
    const connected = document.querySelectorAll('.ant-table-row')[rowIndex].querySelectorAll('.connected');
    connected.forEach((element) => {
      element.classList.remove('hover-effect');
    });
  };

  return (
    <Col span={20}>
      <StyledTable
        borderBottomRightRadius
        borderTopRightRadius
        borderBottomLeftRadius
        borderTopLeftRadius
        $padding
        borderRadius
        paddingLeft
        columns={columns}
        dataSource={tableData}
        onRow={(record, rowIndex) => {
          return {
              onMouseEnter: () => {
                if (rowIndex !== undefined) {
                  setHoveredRowIndex(rowIndex);
                }
              },
              onMouseLeave: () => {
                setHoveredRowIndex(null);
              },
              style: {
                position:'relative',
                zIndex: 1,
              },
          };
        }}
      />
      {open && <IssueModal
        open={open}
        onOkClick={onOkClick}
        issueText={issueText}
      />}
    </Col>
  )
}
