import React from "react";
import { Typography, List } from "antd";
import { ContentLogEntry } from "interfaces";
import { StyledModal } from "components/dashboards/actionmodal.styled";

const { Text } = Typography;

interface ContentLogModalProps {
  visible: boolean;
  logData: ContentLogEntry[];
  onClose: () => void;
}

const getStatusColor = (status: string) => {
  switch (status) {
    case "Prepped":
      return "#00b96b";
    case "Flag Revisit":
      return "#f57c00";
    case "Assigned":
      return "#1677ff";
    case "Re-Assigned":
      return "#f57c00";
    case "Issue":
      return "#FF5757";
    case "Revisit":
      return "#FF66C4";
    default:
      return "#000";
  }
};

export const ContentLogModal: React.FC<ContentLogModalProps> = ({
  visible,
  logData,
  onClose,
}) => {
  const formatLogEntry = (log: ContentLogEntry) => {
    const beforeUser = <Text strong>{log.beforeAssignedUser}</Text>;
    const afterUser = log.afterAssignedUser ? (
      <Text strong>{log.afterAssignedUser}</Text>
    ) : (
      "N/A"
    );

    const color = getStatusColor(log.statusAfter);

    if (log.statusAfter === "Assigned" || log.statusAfter === "Re-Assigned") {
      return (
        <span>
          {log.date} &nbsp; {beforeUser} ran <Text style={{ color }}>{log.statusAfter}</Text> to {afterUser}
        </span>
      );
    } else {
      return (
        <span>
          {log.date} &nbsp; {beforeUser} ran <Text style={{ color }}>{log.statusAfter}</Text>
        </span>
      );
    }
  };

  return (
    <StyledModal
      open={visible}
      title={"Content Log Details"}
      onCancel={onClose}
      footer={null}
      centered
    >
      <div style={{ maxHeight: "600px", overflowY: "auto" }}>
        <List
          dataSource={logData}
          renderItem={(item) => (
            <List.Item
              style={{ padding: "8px 0", margin: 0, display: "flex", alignItems: "flex-start" }}
            >
              <Text style={{ flex: 1 }}>{formatLogEntry(item)}</Text>
            </List.Item>
          )}
        />
      </div>
    </StyledModal>
  );
};
