import React, { useEffect, useState } from "react";
import { useApiUrl, useList } from "@refinedev/core";
import { useLocation, useParams } from "react-router-dom";
import { Col, Row, Typography } from "antd";
import { axiosInstance } from "@refinedev/simple-rest";
import { ContentItemDetail, Developer, IActionObject, LoggedIssue, OperationProgress, ToDoData } from "interfaces";
import { DesktopOutlined } from "@ant-design/icons";
import { BreadCrumbModule } from "components/dashboards/team-leader-dashboard/BreadCrumbModule";
import { ToDoCard } from "components/dashboards/team-leader-dashboard/ToDoCard";
import { ContentTable } from "components/dashboards/team-leader-dashboard/ContentTable";
import { ActionFormModal } from "components/dashboards/team-leader-dashboard/ActionFormModal";
import { useTitle } from "contexts/TitleContext";
import { ReasonModal } from "components/dashboards/team-leader-dashboard/ReasonModal";
import Notification from "components/dashboards/admin-dashboard/Notification";

const { Title } = Typography;

interface ResultData {
    status: string;
    messages: string[];
}

export const ContentOverview: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const location = useLocation();
    const apiUrl = useApiUrl();

    const [version, setVersion] = useState(0);

    const { data } = useList<ContentItemDetail>({
        resource: `dashboard/team_leader/subject/${id}/content?content_type=${location.state.content_type}&version=${version}&`
    });

    const { data: userData } = useList<Developer>({
        resource: `dashboard/team_leader/department/list/${location.state.subject_id}`
    });

    const { data: toDoData } = useList<ToDoData>({
        resource: "dashboard/team_leader/todo",
    });

    const tableData = data?.data ?? [];
    const users = userData?.data ?? [];
    const toDo = toDoData?.data as ToDoData | undefined;

    const { setTitle } = useTitle();
    useEffect(() => {
        setTitle(`${location.state.content_type_name ?? "Content"} (${location.state.subject ?? "Overview"})`);
    }, []);

    const [open, setOpen] = useState(false);
    const [modalStage, setModalStage] = useState<string>("initial");
    const [selectedItem, setSelectedItem] = useState<ContentItemDetail>({} as ContentItemDetail);
    const [operationId, setOperationId] = useState<number>(0);
    const [operationProgress, setOperationProgress] = useState<OperationProgress>({} as OperationProgress);
    const [issueReason, setIssueReason] = useState<string>("");
    const [loggedIssue, setLoggedIssue] = useState<LoggedIssue[]>([]); 
    const [reasonModalVisible, setReasonModalVisible] = useState(false);
    const [resultData, setResultData] = useState<ResultData | null>(null);
    const [showNotification, setShowNotification] = useState(false);

    const handleActionButtonClick = (item: ContentItemDetail) => {
        setSelectedItem(item);
        setModalStage("initial");
        setOpen(true);
        setResultData(null);
        setIssueReason('');
        setShowNotification(false);
    }

    const handleRowIdClick = async (row_id: number) => {
        try {
            const response = await axiosInstance.post(`${apiUrl}/dashboard/team_leader/issue-report`, { row_id }, {
                headers: {
                    "Content-Type": "application/json"
                }
            });
            if (response.status === 200) {
                const data = response.data;
                setLoggedIssue(data);
                setReasonModalVisible(true);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleModal = async (isCancel: boolean, action?: IActionObject) => {
        if (!isCancel && action) {
            if (action.status === "Simulation") {
                await handleSimulationOperation(action);
            }
            else if (action.status === "Toolboxes") {
                await handleToolboxesOperation(action);
            }
            else if (action.status === "Video Lists") {
                await handleVideoListsOperation(action);
            }
            else if (action.status === "UploadType") {
                await handleUploadTypeOperation(action);
            }
            else if (action.status === "CheckExpedition") {
                await handleCheckExpeditionOperation(action);
            }
            else {
                await handleBeginOperation(action);
            }
        }
        else {
            setModalStage("initial");
            setOpen(false);
            setIssueReason('');
        }
    }

    const handleBeginOperation = async (action: IActionObject) => {
        try {
            const response = await axiosInstance.post(`${apiUrl}/dashboard/content`, action, {
                headers: {
                    "Content-Type": "application/json"
                }
            });
            if (response.status === 200) {
                const body = response.data;
                setOperationProgress(body.status);
                setOperationId(body.operationId);
            }
        } catch (error) {
            console.error(error);
        }
    }

    const handleSimulationOperation = async (action: IActionObject) => {
        try {
            const response = await axiosInstance.post(`${apiUrl}/dashboard/validate-simulation`, action, {
                headers: {
                    "Content-Type": "application/json"
                }
            });
            if (response.status === 200) {
                const body = response.data;
                setOperationProgress(body.status);
                setOperationId(body.operationId);
                if (body.result && body.result.messages && body.result.messages.length > 0) {
                    setResultData({
                        status: body.result.status, 
                        messages: [body.result.messages]
                    });
                }
            }

        } catch (error) {
            console.error(error);
        }
    }
    const handleToolboxesOperation = async (action: IActionObject) => {
        try {
            const response = await axiosInstance.post(`${apiUrl}/dashboard/validate-toolboxes`, action, {
                headers: {
                    "Content-Type": "application/json"
                }
            });
            if (response.status === 200) {
                const body = response.data;
                if (body.result && body.result.messages && body.result.messages.length > 0) {
                    setResultData({
                        status: body.result.status,
                        messages: [body.result.messages]
                    });
                }
                setOperationProgress(body.status);
                setOperationId(body.operationId);
            }

        } catch (error) {
            console.error(error);
        }
    }

    const handleVideoListsOperation = async (action: IActionObject) => {
        try {
            const response = await axiosInstance.post(`${apiUrl}/dashboard/validate-videolist`, action, {
                headers: {
                    "Content-Type": "application/json"
                }
            });
            if (response.status === 200) {
                const body = response.data;
                if (body.result && body.result.messages && body.result.messages.length > 0) {
                    setResultData({
                        status: body.result.status,
                        messages: [body.result.messages]
                    });
                }
                setOperationProgress(body.status);
                setOperationId(body.operationId);
            }

        } catch (error) {
            console.error(error);
        }
    }

    const handleUploadTypeOperation = async (action: IActionObject) => {
        try {
            const response = await axiosInstance.post(`${apiUrl}/dashboard/validate-uploadtype`, action, {
                headers: {
                    "Content-Type": "application/json"
                }
            });
            if (response.status === 200) {
                const body = response.data;
                if (body.result && body.result.messages && body.result.messages.length > 0) {
                    setResultData({
                        status: body.result.status,
                        messages: [body.result.messages]
                    });
                }
                setOperationProgress(body.status);
                setOperationId(body.operationId);
            }

        } catch (error) {
            console.error(error);
        }
    }

    const handleCheckExpeditionOperation = async (action: IActionObject) => {
        try {
            const response = await axiosInstance.post(`${apiUrl}/dashboard/validate-quiz`, action, {
                headers: {
                    "Content-Type": "application/json"
                }
            });
            if (response.status === 200) {
                const body = response.data;
                if (body.result && body.result.messages && body.result.messages.length > 0) {
                    setResultData({
                        status: body.result.status,
                        messages: [body.result.messages]
                    });
                }
                setOperationProgress(body.status);
                setOperationId(body.operationId);
            }
        } catch (error) {
            console.error(error);
        }
    }
    
    const getProgress = async () => {
        const response = await axiosInstance.get(`${apiUrl}/dashboard/content/${operationId}/progress`, {
            headers: {
                "Content-Type": "application/json"
            }
        });
        setOperationProgress(response.data);
        return response;
    }

    useEffect(() => {
        if (operationId === 0) return;
        let stayOpen = true;
        let elapsedTime = 0;
        const intervalId = setInterval(async () => {
            if (!stayOpen) {
                handleModalClose();
                setOperationId(0);
                setVersion((v) => v + 1);
                clearInterval(intervalId);
            }
            else {
                try {
                    const response = await getProgress();
                    if (elapsedTime >= 30000 && response.data.percent === 0) {
                        stayOpen = false;
                        setOperationId(0);
                        handleModalClose();
                        clearInterval(intervalId);
                        setResultData({
                            status: "fail",
                            messages: ["Something went wrong. Please try again."]
                        });
                    } else {
                        stayOpen = response.data.percent < 100;
                    }
                    elapsedTime += 1500;
                } catch (error) {
                    console.error(error);
                    setOperationId(0);
                    handleModalClose();
                    setResultData({
                        status: "fail",
                        messages: ["An error occurred while checking progress. Please try again."]
                    });
                    clearInterval(intervalId);
                }
            }
        }, 1500);
        return () => clearInterval(intervalId);
    }, [operationId]);    

    const handleModalClose = async () => {
        setVersion(v => v + 1);
        setModalStage("initial");
        setOperationId(0);
        setOperationProgress({} as OperationProgress);
        setOpen(false);
        setIssueReason('');
        setShowNotification(true);
    }

    const closeModal = () => {
        setReasonModalVisible(false);
        setIssueReason('');
        setLoggedIssue([]);
    }

    const closeNotification = () => {
        setResultData(null);
    };

    const breadCrumbItems = [
        { href: "/dashboard", title: <><DesktopOutlined /><span>Dashboard</span></> },
        { href: `/dashboard/${id}`, title: "Content List" },
    ];

    return (
        <>
            {resultData && showNotification && (
                <Notification data={resultData} onClose={closeNotification} />
            )}
            <Row style={{ marginBottom: '24px' }} gutter={16}>
                <Col span={20}>
                    <Col span={24}>
                        <Title style={{ fontSize: '70px', fontWeight: 'bold' }}>{location.state.content_type_name} ({location.state.subject})</Title>
                    </Col>
                    <Col span={24} style={{ marginLeft: '10px', marginTop: '20px' }}>
                        <BreadCrumbModule items={breadCrumbItems} />
                    </Col>
                </Col>
                <Col span={4}>
                    <ToDoCard needsApproval={toDo?.needs_approval ?? 0} />
                </Col>
            </Row>
            <Row>
                <ContentTable
                    tableData={tableData}
                    handleActionButtonClick={handleActionButtonClick}
                    handleRowIdClick={handleRowIdClick}
                />
                {reasonModalVisible &&
                    <ReasonModal
                        visible={reasonModalVisible}
                        loggedIssue={loggedIssue} 
                        onClose={closeModal}
                    />
                }
                {open &&
                    <ActionFormModal
                        stage={modalStage}
                        item={selectedItem}
                        users={users}
                        open={open}
                        issueReason={issueReason}
                        contentType={location.state.content_type}
                        operationProgress={operationProgress}
                        updateStage={setModalStage}
                        handleModal={handleModal}
                        setIssueReason={setIssueReason}
                    />
                }
            </Row>
        </>
    )
};