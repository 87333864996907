import React, { useEffect, useRef, useState } from 'react';
import { Modal, Spin, Tabs, TabsProps } from 'antd';

import { IImage } from 'interfaces';
import { createPDF, createMemoPDF } from 'utils/functions';

type PdfModalProps = {
    images: IImage[];
    apiUrl: string;
    isModalOpen: boolean;
    subheading: string;
    onClose: () => void;
};

type DefaultPdfModalProps = {
    pdfURL: string;
    isModalOpen: boolean;
    onClose: () => void;
};

export const PdfModal: React.FC<PdfModalProps> = ({ images, apiUrl, isModalOpen, subheading, onClose }) => {
    const [pdfURL, setPdfURL] = useState<string | null>(null);
    const [pdfMemoURL, setPdfMemoURL] = useState<string | null>(null);

    const [pdfHeight, setPdfHeight] = useState<number | null>(null);
    const [pdfMemoHeight, setPdfMemoHeight] = useState<number | null>(null);
    
    const objectRef = useRef<HTMLObjectElement | null>(null);
    const objectMemoRef = useRef<HTMLObjectElement | null>(null);

    const [currentTab, setCurrentTab] = useState<string>('Questions');

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;
        if (isModalOpen) {
            timeoutId = setTimeout(() => {
                createPDF(images, apiUrl, 'portrait', 'Quest', subheading).then(({ pdfURL }) => setPdfURL(pdfURL));
                createMemoPDF(images, apiUrl, 'Scroll').then(({ pdfURL }) => setPdfMemoURL(pdfURL));
            }, 300); // Delay to ensure modal is fully rendered before loading PDFs
        } else {
            console.log('modal close', isModalOpen);
            setPdfURL(null);
            setPdfMemoURL(null);
        }

        return () => clearTimeout(timeoutId);
    }, [isModalOpen]);

    const handlePdfLoad = () => {
        if (objectRef.current && objectRef.current.contentDocument) {
            const pdfDocument = objectRef.current.contentDocument;
            const pdfHeight = pdfDocument.documentElement.scrollHeight;
            setPdfHeight(pdfHeight);
        }
    };

    const handlePdfMemoLoad = () => {
        if (objectMemoRef.current && objectMemoRef.current.contentDocument) {
            const pdfDocument = objectMemoRef.current.contentDocument;
            const pdfHeight = pdfDocument.documentElement.scrollHeight;
            setPdfMemoHeight(pdfHeight);
        }
    };

    const tabItems: TabsProps['items'] = [
        {
            key: 'Questions',
            label: 'Questions',
            children: <>{pdfURL ? (
                <object
                    ref={objectRef}
                    data={`${pdfURL}#zoom=100`}
                    type="application/pdf"
                    width="100%"
                    height={`${pdfHeight || 700}px`}
                    onLoad={handlePdfLoad}
                    style={{ border: 'none', overflow: 'auto' }}
                >
                    <p>It appears your browser does not support embedded PDFs.
                        <a href={pdfURL}>Download the PDF</a> instead.
                    </p>
                </object>

            ) :
                <Spin />}</>
        },
        {
            key: 'Memo',
            label: 'Memo',
            children: <>{pdfMemoURL ? (
                <object
                    ref={objectMemoRef}
                    data={`${pdfMemoURL}#zoom=80`}
                    type="application/pdf"
                    width="100%"
                    height={`${pdfMemoHeight || 700}px`}
                    onLoad={handlePdfMemoLoad}
                    style={{ border: 'none', overflow: 'auto' }}
                >
                    <p>It appears your browser does not support embedded PDFs.
                        <a href={pdfMemoURL}>Download the PDF</a> instead.
                    </p>
                </object>

            ) :
                <Spin />}</>
        }
    ]

    return (
        <Modal
            open={isModalOpen}
            onOk={onClose}
            onCancel={onClose}
            width="60%"
            style={{ padding: 15 }}
            footer={null}
        >
            <Tabs defaultActiveKey={currentTab} items={tabItems} onChange={(key: string) => setCurrentTab(key)} />
        </Modal>
    );
};

export const DefaultPdfModal: React.FC<DefaultPdfModalProps> = ({ pdfURL, isModalOpen, onClose }) => {
    const [pdfHeight, setPdfHeight] = useState<number | null>(null);
    const objectRef = useRef<HTMLObjectElement | null>(null);

    const handlePdfLoad = () => {
        if (objectRef.current && objectRef.current.contentDocument) {
            const pdfDocument = objectRef.current.contentDocument;
            const pdfHeight = pdfDocument.documentElement.scrollHeight;
            setPdfHeight(pdfHeight);
        }
    };

    return (
        <Modal
            open={isModalOpen}
            onOk={onClose}
            onCancel={onClose}
            width="60%"
            style={{ padding: 15 }}
            footer={null}
        >
            {pdfURL ? (
                <object
                    ref={objectRef}
                    data={`${pdfURL}#zoom=100`}
                    type="application/pdf"
                    width="100%"
                    height={`${pdfHeight || 700}px`}
                    onLoad={handlePdfLoad}
                    style={{ border: 'none', overflow: 'auto' }}
                >
                    <p>It appears your browser does not support embedded PDFs.<br/>
                        <a href={pdfURL}>Download the PDF</a> instead.
                    </p>
                </object>

            ) :
                <Spin />}
        </Modal>
    );
};
