import React, { CSSProperties } from "react";
import { List, Typography, Col, Button } from "antd";
import { StyledModal } from "../../actionmodal.styled";

const { Text } = Typography;

interface IActionLegendProps {
  open: boolean;
  onOkClick: () => void;
  onCancelClick: () => void;
}

const legendData = [
  { action: "Add user", description: "Lets you add user to the sheet or drive." },
  { action: "Assign", description: "Assign item to a user with a due date." },
  { action: "Flag Revisit", description: "Updates sheet status to Revisit and a comment." },
  { action: "Link to Folder", description: "Open the associated folder in Drive." },
  { action: "Mark Prepped", description: "Mark the item as prepped and ready." },
  { action: "Re-Assign", description: "Lets you re assign the task to someone." },
  { action: "Resolve Extension", description: "Resolves if the file has double extensions." },
  { action: "Validate", description: "Validates based on the content type from the sheets." },

];

legendData.sort((a, b) => a.action.localeCompare(b.action));

const ActionLegend = ({ open, onOkClick, onCancelClick }: IActionLegendProps) => (
  <StyledModal
    open={open}
    onOk={onOkClick}
    onCancel={onCancelClick}
    title={"Help | Actions"}
    footer={null}
    centered
  >
      <List
        dataSource={legendData}
        renderItem={(item) => (
          <List.Item style={{ padding: '4px 0', margin: 0, display: 'flex', alignItems: 'flex-start' }}>
            <Text strong style={{ marginRight: '8px', width: '120px', display: 'inline-block' }}>{item.action}</Text>
            <Text style={{ flex: 1 }}>{item.description}</Text>
          </List.Item>
        )}
      />
  </StyledModal>
);

const globalStyles = `
  /* For Webkit browsers */
  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 3px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Remove scrollbar buttons (top/bottom) */
  ::-webkit-scrollbar-button {
    display: none;
    height: 0;
  }
`;

const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = globalStyles;
document.head.appendChild(styleSheet);

export default ActionLegend;
